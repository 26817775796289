<template>

  <DefaultCrudView
    @switch-inactive="switchInactive"
    @create-new="newCustomerProperty"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    :inactiveFilterValue="inactiveFilterValue"
    showInactiveFilter
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="customerProperties"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :item-class="itemRowStyle"
        :footer-props="$defaultDataTableFooterProps"
      >

        <template v-slot:item.tenant_id="{ item }">
          <span
            color="primary"
            dark
          >
            {{ getTenantTitle(item.tenant_id) }}
          </span>
        </template>
      
        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editCustomerProperties(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="openSnippetModal(item)"
              >
                mdi-code-tags
              </v-icon>
            </template>
            <span>{{ $t('snippet') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" v-on:submit="save">
        <template v-slot:content>
          <DynamicFormContent 
            :fields="fields"
          ></DynamicFormContent>
        </template>
      </DefaultModal>

      <DefaultModal title="Unomi snippet" ref="snippetModal" hide-save-button>
        <template v-slot:content>
            <code>
              &lt;script type="text/javascript"&gt;
              {{ unomiSnippet }}
              &lt;/script&gt;
            </code>
        </template>
      </DefaultModal>

    </template>
  
  </DefaultCrudView>
</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }

  >>>.inactive-row {
    color: #616161;
    opacity: 0.6;
  }

</style>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import TenantActions from '@/store/core/tenant/actions-types'
import CustomerPropertyActions from '@/store/cdp/customer_property/actions-types'
import CustomerPropertyMutations from '@/store/cdp/customer_property/mutations-types'
import AppActions from '@/store/app/actions-types'

export default {
  name: "CustomerProperty",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent,
  },

  computed: {
    ...mapState({
      fields: state => state.cdp.customer_property.fields,
      customerProperties: state => state.cdp.customer_property.customerProperties,
      selectedCustomerProperty: state => state.cdp.customer_property.selectedCustomerProperty,
      inactiveFilterValue: state => state.cdp.customer_property.inactiveFilterValue,
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('cdp_settings'),
          prependIcon: 'mdi-cog-outline',
          iconColor: 'primary',
          to: {
            name: "CdpSettings",
            params: { appId: this.$route.params.appId }
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: this.$t('customer_properties'),
        },
      ]
    }
  },

  data() {
    return {
      loading: true,
      options: {},
      itemsLength: 0,
      tenants: [],
      headers: [
        {text: 'Key', value: 'customer_property_key'},
        {text: 'Title', value: 'customer_property_title'},
        {text: 'Tenant', value: 'tenant_id'},
        {text: 'Type', value: 'type'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      actions: [
        {
          text: "create_new",
          eventToEmit: "create-new",
        }
      ],
      unomiSnippet: ''
    }
  },

  mounted() {
    this.loading = true
    this.[CustomerPropertyMutations.SET_SELECTED_CUSTOMER_PROPERTY](null)
  
    this.[TenantActions.GET_ALL_TENANTS]()
      .then((tenants) => {
        this.[CustomerPropertyMutations.SET_FIELD_TENANTS](tenants.items)

        this.tenants = [...tenants.items]

        this.reloadData()
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('cdp/customer_property', [
      CustomerPropertyActions.GET_ALL,
      CustomerPropertyActions.SAVE_CUSTOMER_PROPERTY,
    ]),

    ...mapMutations('cdp/customer_property', [
      CustomerPropertyMutations.SET_SELECTED_CUSTOMER_PROPERTY,
      CustomerPropertyMutations.SET_FIELD_TENANTS,
      CustomerPropertyMutations.SET_INACTIVE_FILTER_VAL,
    ]),

    newCustomerProperty() {
      this.[CustomerPropertyMutations.SET_SELECTED_CUSTOMER_PROPERTY](null)
      this.$refs.modal.openModal()
    },

    save() {
      this.[CustomerPropertyActions.SAVE_CUSTOMER_PROPERTY]()
        .then(() => {
          let message = this.selectedCustomerProperty ? 'Customer Property edited' : 'Customer Property created'
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))
          this.$refs.modal.closeModal()
          this.reloadData()
        })
        .catch(() => this.$refs.modal.submitting = false)
    },

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      if (!this.inactiveFilterValue) {
        options = {
          ...options,
          'filter[active]' : "true"
        }
      }

      this.loading = true

      this.[CustomerPropertyActions.GET_ALL](options)
        .then((result) => {
          this.itemsLength = result.total

          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    switchInactive() {
      this.[CustomerPropertyMutations.SET_INACTIVE_FILTER_VAL](!this.inactiveFilterValue)
      this.reloadData()
    },

    editCustomerProperties(customerProperty) {
      this.[CustomerPropertyMutations.SET_SELECTED_CUSTOMER_PROPERTY](customerProperty)

      this.$refs.modal.openModal()
    },

    getModalTitle() {
      return this.selectedCustomerProperty ? this.$t('edit_customer_property') : this.$t('new_customer_property')
    },

    getTenantTitle(id) {
      let tenant = this.tenants.find(f => f.tenant_id == id)

      return tenant ? tenant.title : ''
    },

    itemRowStyle(item) {
      return item.active ? '': 'inactive-row'
    },

    openSnippetModal(customerProperty) {
      this.$refs.snippetModal.openModal()

      let code = "var unomiOption = { scope: 'CUSTOMER_PROPERTY_TITLE', url: 'UNOMI_INSTANCE_URL' }; window.unomiTracker||(window.unomiTracker={}),function(){function e(e){for(unomiTracker.initialize({\"Apache Unomi\":unomiOption});n.length>0;){var r=n.shift(),t=r.shift();unomiTracker[t]&&unomiTracker[t].apply(unomiTracker,r)}}for(var n=[],r=[\"trackSubmit\",\"trackClick\",\"trackLink\",\"trackForm\",\"initialize\",\"pageview\",\"identify\",\"reset\",\"group\",\"track\",\"ready\",\"alias\",\"debug\",\"page\",\"once\",\"off\",\"on\",\"personalize\"],t=0;t<r.length;t++){var i=r[t];window.unomiTracker[i]=function(e){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(e),n.push(r),window.unomiTracker}}(i)}unomiTracker.load=function(){var n=document.createElement(\"script\");n.type=\"text/javascript\",n.async=!0,n.src=unomiOption.url+\"/tracker/unomi-tracker.min.js\",n.addEventListener?n.addEventListener(\"load\",function(n){\"function\"==typeof e&&e(n)},!1):n.onreadystatechange=function(){\"complete\"!==this.readyState&&\"loaded\"!==this.readyState||e(window.event)};var r=document.getElementsByTagName(\"script\")[0];r.parentNode.insertBefore(n,r)},document.addEventListener(\"DOMContentLoaded\",unomiTracker.load),unomiTracker.page()}();"

      code = code.replace('CUSTOMER_PROPERTY_TITLE', customerProperty.customer_property_title)
      code = code.replace('UNOMI_INSTANCE_URL', customerProperty.cdp_instance_url)

      this.unomiSnippet = code
    }
  }
}

</script>